.mobile {display: none;}
.homeSlider { position: relative; z-index: 10;min-height: 800px; position: relative;
  &__Slide {background-size: cover;background-repeat: no-repeat; background-position: center center;position: relative;z-index: 1;
    &:after {position: absolute;content: '';z-index: -1; left: 0;bottom: 0;top: 0;right: 0; background: $black; opacity: 0.5;}
  }
  &__Content { height: 800px; position: relative; float: left; width: 100%;}
  &__Box { width: 70%;margin: auto; position: relative; top: 50%; transform:translateY(-50%); }
  &__Heading { text-align: center; font-size: 90px; line-height: 80px; margin: 0; padding: 0; font-weight: 700;text-transform: uppercase; color: $white; position: relative; }
  .slick-dots { bottom: 0; 
    li {
      button {
        &:before { font-size: 18px; color: $white; opacity: 0.5; }
      }
    }
    li.slick-active {
      button {
        &:before { color: $white; opacity: 1; }
      }
    }
  }
}
.texts { margin: 0; width: 100%; float: left;
  &__Header { float: left;width: 100%;
    h2 { margin-top: 60px; padding: 0px; text-align: center; position: relative; text-transform: uppercase; font-size: em(36px); letter-spacing: 3.5px; line-height: 44px; font-weight: 700;margin-bottom: 0; }
  }
  &__Content {float: left;width: 100%;text-align: center;
    p { font-size: 16px; line-height: 20px;color: $gray2; letter-spacing: 1.5px;text-align: center;}
    a {@include moreButton($brand, $white);margin-top: 30px;
        &:hover {color: $white;}
        &:before {background: $brand;}
     }
  }
}
.topBoxes {margin: 0;width: 100%;float: left; height: 600px; margin-top: 50px;
  &__Left {
    width: 60%; float: left;background-size: cover;background-repeat: no-repeat; background-position: center center;
    &--left {width: 60%;float: left;height: 300px;background-size: cover;background-repeat: no-repeat; background-position: center center;text-align: center;}
    &--text {
      position: relative;top: 50%;display: inline-block;transform:translateY(-50%); width: 70%; margin: auto;
     h2 { padding: 0px; text-align: center; position: relative; text-transform: uppercase; font-size: em(16px); letter-spacing: 3.5px; line-height: 20px; font-weight: 700;margin: 0; }
     p { font-size: 16px; line-height: 20px;color: $gray2; letter-spacing: 1.5px;text-align: center;}
     a {@include moreButton($brand, $white);margin-top: 30px;
        &:hover {color: $white;}
        &:before {background: $brand;}
     }
    }
    &--right {width: 40%;float: right;height: 600px;background-size: cover;background-repeat: no-repeat; background-position: center center;}
  }
    &__Right {
    width: 40%; float: left;background-size: cover;background-repeat: no-repeat; background-position: center center;height: 600px;
  }
}
.bottomBoxes {margin: 0;width: 100%;float: left; height: 400px;
  &__Left {
    width: 60%; float: left;background-size: cover;background-repeat: no-repeat; background-position: center center;
    &--left {width: 40%;float: left;height: 400px;background-size: cover;background-repeat: no-repeat; background-position: center center;text-align: center;}
    &--text {
      position: relative;top: 50%;display: inline-block;transform:translateY(-50%); width: 70%; margin: auto;
     h2 { padding: 0px; text-align: center; position: relative; text-transform: uppercase; font-size: em(16px); letter-spacing: 3.5px; line-height: 20px; font-weight: 700;margin: 0; }
     p { font-size: 16px; line-height: 20px;color: $gray2; letter-spacing: 1.5px;text-align: center;}
     a {@include moreButton($brand, $white);margin-top: 30px;
        &:hover {color: $white;}
        &:before {background: $brand;}
     }
    }
    &--right {width: 60%;float: right;height: 400px;background-size: cover;background-repeat: no-repeat; background-position: center center;text-align: center;}
  }
    &__Right {
    width: 40%; float: left;background-size: cover;background-repeat: no-repeat; background-position: center center;height: 400px;
  }
}
.contact {margin: 60px 0;width: 100%;float: left; min-height: 500px;
  &__Left {
    width: 40%; float: left;
    &--text {
      position: relative;width: 80%; margin-bottom: 50px;
     h2 { padding: 0px; text-align: left; position: relative; text-transform: uppercase; font-size: em(16px); letter-spacing: 3.5px; line-height: 20px; font-weight: 700;margin-top: 0; }
     p { font-size: 16px; line-height: 20px;color: $gray2; letter-spacing: 1.5px;text-align: left;}
     a {text-decoration: none;color: $brand; font-size: 16px;line-height: 20px; letter-spacing: 1.5px; 
      &:hover {color: $black;}
     }
    }
  }
    &__Right {
    width: 60%; float: left;background-size: cover;background-repeat: no-repeat; background-position: center center;height: 400px;
    form {
      input {width: 49%;margin-bottom: 2%; height: 50px;padding: 2%;font-family: $default;font-size: 16px;
        &:focus {outline: 0;}
      }
      .leftInput {float: left; margin-right: 1%;}
      .rightInput {float: right;margin-left: 1%;}
      textarea {width: 100%; height: 300px;max-height: 300px; min-height: 300px; min-width: 100%; max-width: 100%;padding: 2%; margin-bottom: 2%;font-family: $default;font-size: 16px;
        &:focus {outline: 0;}
      }
      .submitButton {@include moreButton($brand, $white);width: 100%;
        &:hover {background: $brand;color: $white;}
      }
    }
  }
}
.map {float: left;width: 100%;height: 400px;}
