.about { text-align: center; padding-bottom: 50px;
  &__Header { text-transform: uppercase; font-size: 36px; line-height: 44px; letter-spacing: 3.5px; font-weight: 700; margin-bottom: 100px;}
  &__Content { font-size: 18px; line-height: 26px;text-align: left; text-indent: 50px;
    a {text-decoration: none; color: $brand;
      &:hover {color: $black;}
    }
    span {font-style: italic; font-weight: 700;}
  }
  &__Image { margin: 0 0 60px 0; float: left;width: 100%; height: 500px; background-size: cover; background-repeat: no-repeat; background-position: center center; }
}
.noIndent {text-indent: 0px;}