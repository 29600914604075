.nav { width: 60%; position: relative; font-size: 14px; float: right; height: 80px; line-height: 80px;
  ul { padding: 0; margin: 0 auto; list-style-type: none; display: table; width: 100%; text-align: right; }
  li { display: inline-block; text-align: center; vertical-align: middle; }
  a { color: $black; text-transform: uppercase; font-size: em(14px); text-decoration: none; line-height: 80px; margin: 0; display: block; padding: 0px 10px; position: relative;font-weight: 600;transition: 300ms $ease-in-out-quint; 
    &:after { content: ""; display: block; height: 1px; position: absolute; top: 50%; left: 50%; right: 50%; opacity: 0; background: $brand; margin-top: 15px; transition: 300ms $ease-in-out-quint; 
    }
    &:hover {color: $brand;}
    &:hover:after { left: 30%; right: 30%; opacity: 1; }
  }
  a.activeMenu {color: $brand;
    &:after { left: 30%; right: 30%; opacity: 1; }
  }
  &__Social {
    a { color: $social; font-size: 32px; display: inline-block; transition: 200ms;
      &:hover { color: $black; }
      &:after { display: none; }
    }
  }

  &--Inline { @extend .nav; 
    ul { display: block; }
    li { display: inline-block; }
  }

  &--Footer { @extend .nav; margin-top: 40px;
    a { line-height: 120px; }
  }
}