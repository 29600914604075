@media screen and (max-width: 960px) { 
  .mobile {display: block;}
/* NAVIGATION START */
.nav { position: absolute; z-index: 1000000000000000000000; float: left; background: $white; @include span-columns(12); left: -125%; transition:500ms; top: 20px;  width: 100%;
  ul { margin: 0; padding: 0; @include clearfix; float: left; width: 75%; text-align: right;background: $white; padding: 10px 0; margin-left: 50%;position: relative; z-index: 100000000000000;} 
  li { @include span-columns(12); line-height: initial; float: none; display: block; height: auto;float: left;width: 100%;  
    a {@include span-columns(12);  background: $white; text-align: center; text-transform: capitalize; font-family: $default;  font-weight: 200; text-decoration: none;color: $black; text-align: left; text-transform: uppercase; height: auto; padding: 15px !important; font-size: 18px !important; line-height: 35px;
      &:hover {color: $black;}
      &:after {display: none;}
    }
    
            }
   &__Button {float: left; text-align: right;font-size: 30px !important; top: 0px; position: absolute; padding-right: 10px; left: 125%; z-index: -1; background: transparent; display: block; top: 15px;
      i {position: absolute; float: left;left: 15%; padding-left: 10px; background: transparent; height: 38px; line-height: 38px; color: $brand; padding-right: 10px;transition:500ms; cursor: pointer; z-index: -1; top: -15px; }
      .fa-close {color: $brand; background: $white;}
      .closeHidden {display: none;transition:500ms;}
      .menuHidden {display: none;transition:500ms;}
    }
  .callNav {width: 40px; height: 40px;  text-align: center; line-height: 40px; color: $white; background: transparent; float: left; display: block;
  i {line-height: 40px; font-size: 24px; height: 40px;width: 40px; transition:500ms; cursor: pointer;}
      .closeHidden {display: none;transition:500ms;}
      .menuHidden {display: none;transition:500ms;}
}
}
.menuActive {left: -50% !important; transition:500ms;}

/* NAVIGATION END */

.siteHeader {
  &__Logo {float: right; width: auto; padding-right: 2%;}
}
.homeSlider { min-height: 500px;   
  &__Content { height: 500px;}
  &__Heading { font-size: 40px; line-height: 40px; }
}
.texts { padding: 5%;
  &__Header {
    h2 { margin-top: 20px;font-size: em(26px); letter-spacing: 3.5px; line-height: 24px;}
  }
}
.topBoxes {margin: 0;width: 100%;float: left; height: auto; margin-top: 50px;
  &__Left {
    width: 100%; height: auto;padding: 5%;
    &--left {width: 100%;height: auto; height: 300px;}
    &--text {width: 100%;}
    &--right {width: 100%;height: auto;}
  }
    &__Right {
    width: 90%; height: 400px;margin: 0 5%;
  }
}
.bottomBoxes {width: 100%;height: auto;
  &__Left {
    width: 100%; height: auto;padding: 5%;
    &--left {width: 100%; height: 500px;}
    &--text {width: 100%;}
    &--right {width: 100%;height: 400px;}
  }
    &__Right {
    width: 90%; height: 400px;margin: 0 5%
  }
}
.contact {margin: 0;
  &__Left {
    width: 100%; padding: 5%;
     &--text {width: 100%;}
  }
    &__Right {
    width: 100%; text-align: center;height: auto;margin-bottom: 50px;
    form {
      input {width: 90%;margin-left: 5%;margin-right: 5%;}
      textarea {width: 90%; margin-left: 5%;margin-right: 5%; min-width: 90%;max-width: 90%;}
      .submitButton {width: 90%;}
    }
  }
}
.siteFooter { height: auto; line-height:initial; padding-top: 10px;padding-bottom: 10px;
  span {font-size: 12px;}
}
.about {
  padding: 5%;
  &__Header {margin-bottom: 0;font-size: 20px;}
  &__Content {font-size: 14px;}
}
}
